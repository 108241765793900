const mortgageOfferNoRRSOMutation = `
mutation updateMortgageOffer($input: UpdateMortgageOfferInput!){
  updateMortgageOffer(input: $input){
    errors,
    mortgageOffer{
      selected,
      id,
      frozen,
      grossLoanAmount,
      ltv,
      totalCostSum,
      firstInstallment,
      lastInstallment,
      totalSum,
      referenceRate,
      maxLoanAmount,
      provisionCost,
      provisionPercentage,
      realEstateAppraisal,
      installmentSchedules{
        installment,
        durationTo,
        durationFrom,
        type,
        firstInstallment,
        lastInstallment
      },
      additionalCosts{
        initialSum,
          content{
          cost,
          creditTheInitialCost,
          totalCost,
          frequency,
          contributionsFeeFrequency,
          name
         }
      },
      nominalRateSchedules{
        margin,
        durationTo,
        durationFrom
        value
      },
    offerCrossSells{
      avgCost,
      contributionAmount,
      crossSell{
        automaticallyInclude,
        costless,
        id,
        key,
        kind,
        name
      },
      crossSellId,
      durationFrom,
      durationTo,
      impactOnMargin,
      included,
      maxCost,
      minCost,
      required,
      totalSum,
      vendorCrossSellId,
      vendorCrossSells{
        id,
        sourceOfAdditionalProduct,
        description,
        name,
        durationTo,
        durationFrom,
        variableCostsInPeriod,
        contributionAmount,
        contributionPercentage,
        contributionsFeeFrequency,
        contributionsFeeCalculationBase
      }
    },
    }
  }
}`;
const mortgageOfferMutation = `
mutation updateMortgageOffer($input: UpdateMortgageOfferInput!){
  updateMortgageOffer(input: $input){
    errors,
    mortgageOffer{
       selected,
      id,
      frozen,
      annualPercentageRate,
      grossLoanAmount,
      ltv,
      totalCostSum,
      firstInstallment,
      lastInstallment,
      totalSum,
      referenceRate,
      maxLoanAmount,
      provisionCost,
      provisionPercentage,
       realEstateAppraisal,
      installmentSchedules{
        installment,
        durationTo,
        durationFrom,
        type,
        firstInstallment,
        lastInstallment
      },
      additionalCosts{
        initialSum,
          content{
          cost,
          creditTheInitialCost,
          totalCost,
          frequency,
          contributionsFeeFrequency,
          name
         }
      },
      nominalRateSchedules{
        margin,
        durationTo,
        durationFrom
        value
      },
    offerCrossSells{
      avgCost,
      contributionAmount,
      crossSell{
        automaticallyInclude,
        costless,
        id,
        key,
        kind,
        name
      },
      crossSellId,
      durationFrom,
      durationTo,
      impactOnMargin,
      included,
      maxCost,
      minCost,
      required,
      totalSum,
      vendorCrossSellId,
      vendorCrossSells{
        id,
        sourceOfAdditionalProduct,
        description,
        name,
        durationTo,
        durationFrom,
        variableCostsInPeriod,
        contributionAmount,
        contributionPercentage,
        contributionsFeeFrequency,
        contributionsFeeCalculationBase
      }
    },
    }
  }
}`;
export { mortgageOfferMutation, mortgageOfferNoRRSOMutation };
