import { Injectable } from '@angular/core';

import { GraphQLService } from '../../graphql.service';
import { mortgageOfferQuery, mortgageOfferRRSOQuery } from './queries/mortgage-offer-query';
import { mortgageOfferMutation, mortgageOfferNoRRSOMutation } from './queries/mortgage-offer-mutation';

@Injectable({
  providedIn: 'root'
})
export class MortgageOffersQLService {
  constructor(readonly graphQLService: GraphQLService) {}

  findOffersBySimulationId(
    simulationId: string,
    sorts?: string[],
    selected?: boolean,
    creditworthiness?: boolean,
    productVendorIdIn?: string[],
    productPromotion?: boolean,
    productNoEarlyRepaymentFee?: boolean,
    productInterestRateType?: string,
    noProvision?: boolean,
    productVariantIncluded?: boolean & null
  ) {
    return this.graphQLService.fetch(mortgageOfferQuery, {
      simulationId,
      sorts,
      selected,
      creditworthiness,
      productVendorIdIn,
      productPromotion,
      productNoEarlyRepaymentFee,
      productInterestRateType,
      noProvision,
      productVariantIncluded
    });
  }

  findOffersRRSOBySimulationId(
    simulationId: string,
    sorts?: string[],
    selected?: boolean,
    creditworthiness?: boolean,
    productVendorIdIn?: string[],
    productPromotion?: boolean,
    productNoEarlyRepaymentFee?: boolean,
    productInterestRateType?: string,
    noProvision?: boolean,
    productVariantIncluded?: boolean & null
  ) {
    return this.graphQLService.fetch(mortgageOfferRRSOQuery, {
      simulationId,
      sorts,
      selected,
      creditworthiness,
      productVendorIdIn,
      productPromotion,
      productNoEarlyRepaymentFee,
      productInterestRateType,
      noProvision,
      productVariantIncluded
    });
  }

  updateOffer(offer, withRRSO = true) {
    return this.graphQLService.mutate(withRRSO ? mortgageOfferMutation : mortgageOfferNoRRSOMutation, { input: offer });
  }
}
