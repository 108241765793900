import { MortgageOffer } from 'exoffice-js-client';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import * as MortgageOffersEntitiesActions from './mortgage-offers.actions';

type Action = MortgageOffersEntitiesActions.All;

export const mortgageOffersAdapter = createEntityAdapter<MortgageOffer>();

export interface MortgageOffersEntities extends EntityState<MortgageOffer> {
  loading: boolean;
  loaded: boolean;
  totalElements: number;
}

export const initialState: MortgageOffersEntities = mortgageOffersAdapter.getInitialState({
  // additional entity state properties
  loading: false,
  loaded: false,
  totalElements: 0
});

export default function MortgageOffersReducer(
  state: Readonly<MortgageOffersEntities> = initialState,
  action?: Action
): any {
  switch (action.type) {
    case MortgageOffersEntitiesActions.ADD_ONE:
      return mortgageOffersAdapter.addOne(action.offer, state);
    case MortgageOffersEntitiesActions.ADD_ALL: {
      return mortgageOffersAdapter.addAll(action.payload.offers, state);
    }
    case MortgageOffersEntitiesActions.UPSERT_OFFERS: {
      return mortgageOffersAdapter.upsertMany(action.payload.offers, state);
    }
    case MortgageOffersEntitiesActions.UPDATE_ONE:
      return mortgageOffersAdapter.updateOne({ id: action.id, changes: action.changes }, state);

    case MortgageOffersEntitiesActions.REMOVE_ALL:
      return mortgageOffersAdapter.removeAll(state);

    case MortgageOffersEntitiesActions.LOAD_MORTGAGE_OFFERS: {
      return {
        ...state,
        loaded: false,
        loading: true
      };
    }

    case MortgageOffersEntitiesActions.LOAD_MORTGAGE_OFFERS_SUCCESS: {
      return {
        ...mortgageOffersAdapter.addAll(action.payload.content, state),
        loaded: true,
        loading: false
      };
    }

    case MortgageOffersEntitiesActions.LOAD_MORTGAGE_OFFERS_FAILED: {
      return {
        ...state,
        loaded: false,
        loading: false
      };
    }

    case MortgageOffersEntitiesActions.MAP_OFFERS: {
      return mortgageOffersAdapter.map(action.map, state);
    }

    default:
      return state;
  }
}
