import { HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { ROUTES } from './app.routes';
// App views
import { LoginModule } from './views/login/login.module';
import { MainViewModule } from './views/main-view/main-view.module';
import { MinorViewModule } from './views/minor-view/minor-view.module';
import { RegisterModule } from './views/register/register.module';
import { SimulationWizardModule } from './views/simulations/simulation-wizard/simulation-wizard.module';
import { SimulationModule } from './views/simulations/simulation/simulation.module';

// Error handling
import { ErrorHandlingModule } from './views/error-handling/error-handling.module';

// App modules/components
import { LayoutsModule } from './components/common/layouts/layouts.module';

import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OfferContentModule } from './components/common/offer-content/offer-content.module';

import localePl from '@angular/common/locales/pl';
import { OAuthModule } from 'angular-oauth2-oidc';
import { ErrorStatusHttpInterceptor } from './interceptors/error-status-http.interceptor';
import { cashSimulationServiceProvider } from './services/simulation/cash/cash-simulation.service';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { CurrentAccountNgrxService } from './services/ngrx/current-account-ngrx.service';
import { EffectsModule } from '@ngrx/effects';
import { accountServiceProvider } from './services/providers/account-service.provider';
import { DictionariesNgrxService } from './services/ngrx/dictionaries-ngrx.service';
import { mortgageSimulationServiceProvider } from './services/simulation/mortgage/mortgage-simulation.service';
import { simulationServiceProvider } from './services/simulation/simulation.service';
import { cityServiceProvider } from './services/providers/city/city-service.provider';
import { SimulationNgrxService } from './services/ngrx/simulation-ngrx.service';
import { mortgageOfferServiceProvider } from './services/providers/mortgage-offer-service.provider';
import { cashOfferServiceProvider } from './services/providers/cash-offer-service.provider';
import { vendorServiceProvider } from './services/vendor/vendor.provider';
import { VendorsNgrxService } from './services/ngrx/vendors-ngrx.service';
import { OfferFiltersNgrxService } from './services/ngrx/offer-filters-ngrx.service';
import { effectsArray } from './effects/effects';
import { reviewServiceProvider } from './services/simulation/reviews/reviews.service';
import { ExpandedCrossSellsNgrxService } from './services/ngrx/expanded-cross-sells-ngrx.service';
import { KnowledgeBaseModule } from './views/knowledge-base/knowledge-base.module';
import { ProductTypesNgrxService } from './services/ngrx/product-types-ngrx.service';
import { NgxCurrencyModule } from 'ngx-currency';
import { ModalModule, TooltipModule } from 'ngx-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

registerLocaleData(localePl);

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, 'assets/lang/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    // Angular modules
    BrowserModule,
    HttpClientModule,
    // Vendor modules
    OAuthModule.forRoot(),
    // Views
    MainViewModule,
    MinorViewModule,
    LoginModule,
    RegisterModule,
    SimulationModule,
    SimulationWizardModule,
    ErrorHandlingModule,
    // Modules
    LayoutsModule,
    OfferContentModule,
    FormsModule,
    BrowserAnimationsModule,
    KnowledgeBaseModule,
    RouterModule.forRoot(ROUTES),
    ToastrModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 10
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    EffectsModule.forRoot([...effectsArray]),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    NgxCurrencyModule,
    FontAwesomeModule
  ],
  providers: [
    cashSimulationServiceProvider,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    { provide: LOCALE_ID, useValue: 'pl-PL' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorStatusHttpInterceptor,
      multi: true
    },
    CurrentAccountNgrxService,
    ExpandedCrossSellsNgrxService,
    DictionariesNgrxService,
    accountServiceProvider,
    reviewServiceProvider,
    mortgageSimulationServiceProvider,
    simulationServiceProvider,
    cityServiceProvider,
    SimulationNgrxService,
    VendorsNgrxService,
    ProductTypesNgrxService,
    OfferFiltersNgrxService,
    mortgageOfferServiceProvider,
    cashOfferServiceProvider,
    vendorServiceProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
